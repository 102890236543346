<template>
  <div>
    <!-- <div>
      <button @click="printEditorHtml">print html</button>
      <button @click="insertTextHandler">insert text</button>
      <button @click="disableHandler">disable</button>
    </div> -->
    <div style="border: 1px solid #ccc; margin-top: 10px">
      <!-- 工具栏 -->
      <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editor"
        :defaultConfig="toolbarConfig"
      />
      <!-- 编辑器 -->
      <Editor
        :style="{ height: eheight + 'px', overflowy: 'hidden',zIndex:'1' }"
        :defaultConfig="editorConfig"
        v-model="html"
        @onChange="onChange"
        @onCreated="onCreated"
      />
    </div>
    <!-- <div style="margin-top: 10px">
      <textarea
        v-model="html"
        readonly
        style="width: 100%; height: 200px; outline: none"
      ></textarea>
    </div> -->
  </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import global from "../common";

export default {
  name: "MyEditor",
  components: { Editor, Toolbar },
  props: {
    content: String,
    showback: String,
    eheight: {
      type: String,
      default: "400",
    },
  },
  watch: {
    content: {
      immediate: true, // 这句重要
      handler(val) {
        this.contentchange(val);
      },
    },
  },
  data() {
    return {
      editor: null,
      html: "abc",
      toolbarConfig: {
        // toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
        // excludeKeys: [ /* 隐藏哪些菜单 */ ],
      },
      editorConfig: {
        placeholder: "请输入内容...",
        pasteFilterStyle: false,
        // autoFocus: false,

        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          uploadImage: {
            // base64LimitSize: 5 * 1024 * 1024, // 5mb
            server: global.baseUrl + "/ew_upload_image.php",
            // form-data fieldName，后端接口参数名称，默认值wangeditor-uploaded-image
            fieldName: "file",
            // 1M，单个文件的最大体积限制，默认为 2M
            maxFileSize: 4 * 1024 * 1024,
            // 最多可上传几个文件，默认为 100
            maxNumberOfFiles: 10,
            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ["image/*"],
            // 15 秒，超时时间，默认为 10 秒
            timeout: 15 * 1000,
            onFailed(file, res) {
              console.log(`${file.name} 上传失败`, res);
            },
            // 上传错误，或者触发 timeout 超时
            onError(file, err, res) {
              alert(err);
              console.log(`${file.name} 上传出错`, err);
              console.log(`res:`, res);
            },
          },
          uploadVideo: {
            // base64LimitSize: 5 * 1024 * 1024, // 5mb
            server: global.baseUrl + "/ew_upload_image.php",
            // form-data fieldName，后端接口参数名称，默认值wangeditor-uploaded-image
            fieldName: "file",
            // 1M，单个文件的最大体积限制，默认为 2M
            maxFileSize: 100 * 1024 * 1024,
            // 最多可上传几个文件，默认为 100
            maxNumberOfFiles: 10,
            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ["video/*"],
            // 15 秒，超时时间，默认为 10 秒
            timeout: 15 * 1000,
            onFailed(file, res) {
              console.log(`${file.name} 上传失败`, res);
            },
            // 上传错误，或者触发 timeout 超时
            onError(file, err, res) {
              alert(err);
              console.log(`${file.name} 上传出错`, err);
              console.log(`res:`, res);
            },
          },
        },
      },
    };
  },
  methods: {
    contentchange(content) {
      // content = content.replace(/section/g, "p").replace(/div/g, "p");
      console.log("contentchange123:",content);
      // //   setTimeout(() => {

      this.html = content;
      // //   }, 1500);
    },
    seteditor() {
      // this.editor.config.uploadImgShowBase64 = true;
      // this.editor.config.uploadImgMaxSize = 6 * 1024 * 1024;
      //   console.log("this.editor:", this.editor);
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 【注意】一定要用 Object.seal() 否则会报错
      console.log(this.editor);
    },
    onChange(editor) {
      //   console.log("onChange", editor.getHtml()); // onChange 时获取编辑器最新内容
      this.$emit("HtmlonChange", editor.getHtml());
    },
    insertTextHandler() {
      const editor = this.editor;
      if (editor == null) return;
      editor.insertText(" hello ");
    },
    printEditorHtml() {
      const editor = this.editor;
      if (editor == null) return;
      console.log(editor.getHtml());
    },
    disableHandler() {
      const editor = this.editor;
      if (editor == null) return;
      editor.disable();
    },
  },
  mounted() {
    // 模拟 ajax 请求，异步渲染编辑器
    // setTimeout(() => {
    //     this.html = '<p>Ajax 异步设置内容 HTML</p>'
    // }, 1500)
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁 editor ，重要！！！
  },
};
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>